// @flow

import React, { Component } from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { FilterService } from '@riseart/filter-service';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectUnitSystem } from 'shared_services/redux/selectors/unitSystem';
import { withFilterState } from 'shared_hocs/filters/withFilterState';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { pathToParams } from 'shared_services/riseart/utils/FilterRouteUtils';
import { updateFilters } from 'shared_services/redux/actions/application/filters';
import { DEFAULT_STORE_STATE as FILTER_DEFAULT_STATE } from 'shared_services/redux/states/filter';
import { MetaService } from 'shared_services/riseart/meta/Meta';
import { pagination as PAGINATION_ENUM, meta as META_ENUM } from 'Enum';
import { application as APP_CONFIG } from 'Config';

const {
  ATTRIBUTE_CONTENT,
  METATYPE: { LINK_CANONICAL, LINK_CANONICAL_REDIRECT, META_ROBOTS },
  SUBSCRIBER_NAME: { FILTER_PAGE_ROUTE_META: FILTER_META_SUBSCRIBER },
} = META_ENUM;
const { INFINITE } = PAGINATION_ENUM.type;
const HOC_DISPLAY_NAME = 'HOCFilterPageRoute';

// transformation handlers for validation or preparation/transformation of url paramers
const paramsTransformationHandlers = {
  page: (page) => parseInt(page, 10),
};

type Props = {
  match: Object,
  urlParams: Object,
  location: Object,
  history: Object,
  currentLocale: Object,
  domain: string,
  staticContext?: any,
  paginationType: string,
  actionUpdateFilters: Function,
  storeCode: ?string,
  unitLength: ?string,
  disableHreflangMeta?: boolean,
};

/**
 * FilterPageRoute
 *
 * returns {Function}
 */
function FilterPageRoute(PageComponent: any): any {
  return class extends Component<Props, *> {
    static displayName = HOC_DISPLAY_NAME;

    assembledUrl: string = '';

    filterService: Object;

    constructor(props: Props) {
      super(props);

      this.filterService = new FilterService(
        getFilterDomainConfig(props.domain, props.urlParams || props.match.params),
      );
    }

    /**
     * shouldComponentUpdate
     * @param {*} nextProps
     */
    shouldComponentUpdate() {
      return false;
    }

    /**
     * UNSAFE_componentWillMount
     */
    // eslint-disable-next-line
    UNSAFE_componentWillMount() {
      // Overwrite the page state when we have an infinite scroll page loaded
      const overwriteStateProps =
        this.props.paginationType === INFINITE
          ? { page: FILTER_DEFAULT_STATE[this.props.domain].page }
          : {};
      this.mapRouteProps(this.props, overwriteStateProps);
    }

    /**
     * componentWillUnmount
     */
    // TODO: Uncomment this when we have properly separated redux store state keys
    // for dirrent filter states per domain. For not it is not possible to use it
    // because componentWillUnmount is async after rRact v16 and it might
    // clear the store state after component has mounted and user navigates
    // between two page components that use this component
    componentWillUnmount() {
      this.props.actionUpdateFilters({ ...FILTER_DEFAULT_STATE[this.props.domain] });
    }

    /**
     * UNSAFE_componentWillReceiveProps
     * @param {*} nextProps
     */
    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
      const {
        storeCode: prevStoreCode,
        unitLength: prevUnitLength,
        location: prevLocation,
      } = this.props;
      const {
        storeCode: nextStoreCode,
        unitLength: nextUnitLength,
        location: nextLocation,
      } = nextProps;

      // Set page to 1 if storeCode is set and if has changed
      const overwrittenPageState =
        nextProps.paginationType === INFINITE &&
        prevStoreCode &&
        nextStoreCode &&
        prevStoreCode !== nextStoreCode
          ? { page: FILTER_DEFAULT_STATE[this.props.domain].page }
          : null;

      // Only call/rerender if location properties are different or if storeCode was changed
      if (
        overwrittenPageState ||
        prevUnitLength !== nextUnitLength ||
        prevLocation.key !== nextLocation.key ||
        prevLocation.pathname !== nextLocation.pathname ||
        prevLocation.search !== nextLocation.search ||
        prevLocation.hash !== nextLocation.hash ||
        prevLocation.state !== nextLocation.state
      ) {
        this.mapRouteProps(nextProps, overwrittenPageState);
      }
    }

    /**
     * mapRouteProps
     * @param {*} props
     * @param {} overwriteStateProps
     */
    mapRouteProps(props: Props, overwriteStateProps?: Object = null): void {
      const {
        location: { search: queryParams, pathname },
        match: { url: matchRouteUrl },
      } = props;
      const filterConfig = this.filterService.getConfig();
      const urlParams = pathToParams(
        pathname.replace((props.urlParams && props.urlParams.url) || matchRouteUrl, ''),
      );
      const filterData = this.filterService.route(urlParams, queryParams);
      let filterStoreValues = {
        ...FILTER_DEFAULT_STATE[this.props.domain],
        // if textSearch (q) param is provided, then the default sort is changed
        ...((filterData.q !== undefined &&
          !filterData.sort &&
          filterConfig.textSearchDefaultSort && { sort: filterConfig.textSearchDefaultSort }) ||
          null),
        ...filterData,
        ...overwriteStateProps,
        routed: true,
      };
      filterStoreValues = Object.keys(filterStoreValues).reduce(
        (accumulator: Object, key: string): Object => {
          const handler = paramsTransformationHandlers[key];
          const item = filterStoreValues[key];

          return { ...accumulator, [key]: typeof handler === 'function' ? handler(item) : item };
        },
        {},
      );

      // Push new state to redux store
      this.props.actionUpdateFilters(filterStoreValues);

      // Assemble pageUrl with selected filters and valid querystring params (sort, layout, page)
      this.assembledUrl = `${LocationManager.get('origin')}${this.filterService.assemble(
        filterStoreValues.filters,
        pick(filterStoreValues, ['sort', 'layout', 'page', 'q']),
        FILTER_DEFAULT_STATE[this.props.domain],
      )}`;

      // Always push canonicalLink. The meta service will determine
      // if it will be rendered or not depending on robots noindex meta data
      const collectedMeta = [
        {
          type: LINK_CANONICAL,
          value: `${LocationManager.get('origin')}${this.filterService.assembleCanonicalUrl(
            filterStoreValues.filters,
          )}`,
        },
        {
          type: LINK_CANONICAL_REDIRECT,
          value: this.filterService.assemble(filterStoreValues.filters),
        },
      ];

      // Set robots="noindex" if there is no noindex already set in meta,
      // or if the filters is detected as noindex page url
      if (!MetaService.has({ type: META_ROBOTS, value: ATTRIBUTE_CONTENT.NO_INDEX })) {
        const { filters: filterParams, ...restFilterParams } = filterData || {};

        if (this.filterService.isNoindexFilter({ ...filterParams, ...restFilterParams })) {
          collectedMeta.push({ type: META_ROBOTS, value: ATTRIBUTE_CONTENT.NO_INDEX });
        } else {
          MetaService.removeByType(META_ROBOTS);
        }
      }

      const hreflangUrls = APP_CONFIG.i18n.locales.reduce(
        (accumulator, locale) => {
          const service = new FilterService({
            ...getFilterDomainConfig(props.domain, {
              ...(props.urlParams || props.match.param),
              locale,
            }),
            locale: locale.name,
          });
          const localeUrl = service.assemble(filterStoreValues.filters);

          accumulator.absolute[locale.name] = localeUrl
            ? `${LocationManager.get('origin')}${localeUrl}`
            : null;

          accumulator.relative[locale.name] = localeUrl || null;
          return accumulator;
        },
        { absolute: {}, relative: {} },
      );

      if (hreflangUrls && !props.disableHreflangMeta) {
        collectedMeta.push(
          {
            type: META_ENUM.METATYPE.LINK_HREFLANG,
            value: { type: 'generated', href: hreflangUrls.absolute },
          },
          {
            type: META_ENUM.METATYPE.LINK_HREFLANG_REDIRECT,
            value: { type: 'generated', href: hreflangUrls.relative },
          },
        );
      }

      MetaService.add(collectedMeta, FILTER_META_SUBSCRIBER);

      this.forceUpdate();
    }

    /**
     * render
     * @returns {React.Element}
     */
    render() {
      return <PageComponent {...this.props} pageUrl={this.assembledUrl} />;
    }
  };
}

const HOCFilterPageRoute = (DecoratedComponent: React$Component<*, *>, filterDomain: string) =>
  withFilterState(
    filterDomain,
    connect((state) => ({
      storeCode: selectStoreCode(state),
      unitLength: selectUnitSystem(state).unitLength,
    }))(FilterPageRoute(DecoratedComponent)),
    () => ({}),
    {
      actionUpdateFilters: updateFilters(filterDomain),
    },
  );

export default HOCFilterPageRoute;
