// @flow

import React, { Component } from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { injectIntl } from 'react-intl';
import { FilterService } from '@riseart/filter-service';
import { withFilterState } from 'shared_hocs/filters/withFilterState';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';
import {
  DEFAULT_STORE_STATE as FILTER_DEFAULT_STATE,
  STORE_KEYS as FILTER_STORE_KEYS,
} from 'shared_services/redux/states/filter';
import { gtmTabOpen, gtmTabClose } from 'shared_data/providers/google/tagmanager/Actions';
import { MESSAGE_TYPES, RiseartLogger } from 'shared_services/riseart/Logger';

// Constants
const HOC_DISPLAY_NAME = 'HOCGridSort';

/**
 * HOC
 *
 * @param {React$Component<*, *>} SortSection
 * @param {Object} options
 */
function HOC(SortSection: React$Component<*, *>, options: Object = {}) {
  return class extends Component<Object, *> {
    static displayName = HOC_DISPLAY_NAME;

    static defaultProps = {
      filterState: {},
    };

    filterService: Object;

    availableSortOptions: Object;

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props) {
      super(props);

      this.bindMethods();
      this.availableSortOptions = this.optionsFactory(options.sortList, this.props.translationPath);
      this.filterService = new FilterService(
        getFilterDomainConfig(this.props.domain, this.props.match.params),
        null,
        FILTER_DEFAULT_STATE[this.props.domain],
      );
    }

    /**
     * getSortOptions
     *
     * @returns {*}
     */
    getSortOptions(currentSort) {
      if (!currentSort) {
        currentSort = FILTER_DEFAULT_STATE[this.props.domain][FILTER_STORE_KEYS.SORT];
      }

      Object.keys(this.availableSortOptions).forEach((key) => {
        this.availableSortOptions[key].selected =
          this.availableSortOptions[key].value === currentSort;
      });
      return this.availableSortOptions;
    }

    /**
     * optionsFactory
     *
     * @param {Object} data
     * @param {string} dictionaryBasePath
     * @returns {Array<Object>}
     */
    optionsFactory(optionsData: Object, dictionaryBasePath: string): Array<Object> {
      if (!optionsData || !dictionaryBasePath) {
        RiseartLogger.message({
          message:
            'In order to use the options factory you need to define an array of options and the dictionary path',
          level: MESSAGE_TYPES.ERROR,
          data: {
            scope: 'HOCGridSort',
            optionsData,
            dictionaryBasePath,
          },
        });
      }

      return Object.keys(optionsData).map((key) => ({
        label: this.props.intl.formatMessage({ id: `${dictionaryBasePath}.${optionsData[key]}` }),
        value: optionsData[key],
        url: null,
        selected: false,
      }));
    }

    /**
     * sortHandler
     *
     * @param selectedSorted
     */
    sortHandler: Function;

    sortHandler(selectedSorted) {
      const filterDomainConfig = this.filterService.getConfig();

      this.props.history.push(
        this.filterService.assemble(
          this.props.filterState.filters,
          {
            layout: this.props.filterState.layout,
            sort: selectedSorted,
            q: this.props.filterState.q,
          },
          {
            ...FILTER_DEFAULT_STATE[this.props.domain],
            // if textSearch (q) is provided, then default value of the filter
            // may be different depending on the domain config
            ...(this.props.filterState.q !== undefined && filterDomainConfig.textSearchDefaultSort
              ? { sort: filterDomainConfig.textSearchDefaultSort }
              : null),
          },
        ),
      );
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.sortHandler = this.sortHandler.bind(this);
    }

    /**
     * render
     */
    render() {
      const { filterState, intl } = this.props;

      return (
        <SortSection
          label={intl.formatMessage({ id: 'components.art.sort' })}
          options={this.getSortOptions(filterState.sort)}
          onChange={this.sortHandler}
        />
      );
    }
  };
}

/**
 * HOCGridSort
 *
 * @param HOCGridSort
 * @returns {*}
 */
export function HOCGridSort(SortSection: React$Component<*, *>, options: Object) {
  return withTranslatedRouter(
    injectIntl(
      withFilterState(options.domain, HOC(SortSection, options), undefined, {
        actionGtmTabOpen: gtmTabOpen,
        actionGtmTabClose: gtmTabClose,
      }),
    ),
  );
}
